<template>
  <div class="row">
    <div class="col-md-12">
      <apexchart
        height="360"
        width="100%"
        type="bar"
        :options="options || {}"
        :series="series"></apexchart>
    </div>
  </div>
</template>

<script>
  export default {
    name: "billing-history",
    props: {
      categoriesData: {
        required: true,
        type: Array,
      },
      totalRecipients: {
        required: true,
        type: Array,
      },
    },
    data() {
      return {
        // series: this.totalRecipients,
        options: {
          plotOptions: {
            bar: {
              borderRadius: 10,
            },
          },
          colors: ["#F7921C", "#30A1F0"],
          chart: {
            // id: "chart-billing",
            toolbar: {
              show: false,
            },
          },
          xaxis: {
            categories: this.categoriesData,
          },
          stroke: {
            show: true,
            width: 8,
            colors: ["#fff"],
          },
          grid: {
            yaxis: {
              lines: {
                show: true,
              },
            },
          },
          dropShadow: {
            enabled: false,
          },
          dataLabels: {
            enabled: false,
          },
          yaxis: {
            labels: {
              show: false,
              formatter: function (val) {
                return val.toFixed(0);
              },
            },
            type: "numeric",
            floating: false,
          },
          legend: {
            show: false,
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val.toLocaleString();
              },
            },
          },
        },
      };
    },
    computed: {
      series: function () {
        return this.totalRecipients;
      },
    },

  };
</script>
