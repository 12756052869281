<template>
  <div
    class="row justify-content-center align-items-center"
    style="width: 100%; margin: 0px; padding: 0px">
    <div class="col-md-12 chart_section">
      <div id="chart" class="" ref="chart">
        <apexcharts
          type="donut"
          width="100%"
          height="100%"
          :options="chartOptions"
          :series="chartOptions.series">
        </apexcharts>
      </div>
    </div>
  </div>
</template>

<script>
  import VueApexCharts from "vue-apexcharts";
  import loginType from "../../../helpers/loginType";
  export default {
    components: {
      apexcharts: VueApexCharts,
    },
    props: {
      campaignLabels: {
        required: true,
        type: Array,
      },
      CampaignCount: {
        required: true,
        type: Array,
      },
    },
    data: function () {
      return {
        campaign: "",
        colors: [],
      };
    },
    created() {
      if (loginType() == "sms") {
        this.campaignLabels.forEach((label) => {
          if (label.toLowerCase() === "DELIVERED".toLocaleLowerCase()) {
            this.colors.push("#36D962");
          } else if (
            label.toLowerCase() === "NOT_DELIVERED".toLocaleLowerCase()
          ) {
            this.colors.push("#000");
          } else if (label.toLowerCase() === "SUBMITTED".toLocaleLowerCase()) {
            this.colors.push("#30A1F0");
          } else if (
            label.toLowerCase() === "FAILED".toLocaleLowerCase() ||
            label.toLowerCase() === "ACCEPTED".toLocaleLowerCase()
          ) {
            this.colors.push("#f0c630");
          } else if (label.toLowerCase() === "EXPIRED".toLocaleLowerCase()) {
            this.colors.push("#F7921C");
          } else if (label.toLowerCase() === "REJECTED".toLocaleLowerCase()) {
            this.colors.push("#bc76ba");
          }
        });
      } else {
        this.campaignLabels.forEach((label) => {
          if (label.toLowerCase() === "ANSWERED".toLowerCase()) {
            this.colors.push("#36D962");
          } else if (label.toLowerCase() === "NO ANSWER".toLowerCase()) {
            this.colors.push("#000");
          } else if (label.toLowerCase() === "PENDING".toLowerCase()) {
            this.colors.push("#30A1F0");
          } else if (label.toLowerCase() === "BUSY".toLowerCase()) {
            this.colors.push("#FF0000");
          } else if (label.toLowerCase() === "not_dialed_yet".toLowerCase()) {
            this.colors.push("#30A1F0");
          }
        });

        this.colors = this.colors.filter((e) => e !== undefined);
      }
    },

    computed: {
      chartOptions: function () {
        return {
          colors: this.colors,
          tooltip: {
            y: {
              formatter: undefined,
              title: {
                formatter: (seriesName) => {
                  return seriesName
                    .replace(/_/g, " ")
                    .replace(/\w\S*/g, function (txt) {
                      return (
                        txt.charAt(0).toUpperCase() +
                        txt.substr(1).toLowerCase()
                      );
                    });
                },
              },
            },
          },
          legend: {
            fontSize: "14px",
            fontWeight: 400,
            floating: false,
            offsetX: 0,
            offsetY: 0,
            height: 200,
            itemMargin: {
              horizontal: 30,
              vertical: 15,
            },
            formatter: function (seriesName, opts) {
              let total = 0;
              let series = opts.w.globals.series;
              let singleSeries = opts.w.globals.series[opts.seriesIndex];
              for (let i = 0; i < series.length; i++) {
                total += series[i];
              }
              let perCentage = parseInt((singleSeries / total) * 100);
              seriesName = seriesName
                .replace(/_/g, " ")
                .replace(/\w\S*/g, function (txt) {
                  return (
                    txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
                  );
                });
              return `
                        <div style='display:inline-grid; width:300px'> 
                            <div style='grid-area: 1 / 1 / 2 / 2; place-self: start; width:100px;margin:0' >${singleSeries.toLocaleString()}</div>
                            <div style='grid-area: 1 / 2 / 2 / 3; place-self: center;text-align:center;width:100px'>${seriesName}</div>
                            <div style='grid-area: 1 / 3 / 2 / 4; place-self: end; width:100px;text-align:right;'>${perCentage}% </div>
                        </div>
                        `;
            },
          },
          dataLabels: {
            enabled: false,
          },
          labels: this.campaignLabels,
          plotOptions: {
            pie: {
              size: 200,
              expandOnClick: false,
              donut: {
                size: "88%",
                background: "transparent",
                labels: {
                  show: true,
                  name: {
                    show: true,
                    offsetY: -10,
                    formatter: function (val) {
                      // remove underscore and capitalize each word after a space
                      return val
                        .replace(/_/g, " ")
                        .replace(/\w\S*/g, function (txt) {
                          return (
                            txt.charAt(0).toUpperCase() +
                            txt.substr(1).toLowerCase()
                          );
                        });
                    },
                  },
                  value: {
                    show: true,
                    fontSize: "16px",
                    color: "#575761",
                    fontStyle: "normal",
                    fontWeight: "bold",
                    fontSize: "29px",
                    lineHeight: "32px",
                    formatter: function (val) {
                      return val;
                    },
                  },
                  total: {
                    show: true,
                    label: "Total Messages",
                    color: "#575761",
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: "12px",
                    lineHeight: "13px",
                    formatter: function (w) {
                      return w.globals.seriesTotals.reduce((a, b) => {
                        return a + b;
                      }, 0);
                    },
                  },
                },
              },
            },
          },
          series: this.CampaignCount,
          responsive: [
            {
              breakpoint: 768,
              options: {
                legend: {
                  position: "bottom",
                  height: 200,
                  itemMargin: {
                    horizontal: 0,
                    vertical: 0,
                  },
                  formatter: function (seriesName, opts) {
                    let total = 0;
                    let series = opts.w.globals.series;
                    let singleSeries = opts.w.globals.series[opts.seriesIndex];
                    for (let i = 0; i < series.length; i++) {
                      total += series[i];
                    }
                    let perCentage = parseInt((singleSeries / total) * 100);
                    // series name remove underscore and capitalize first letter of each word
                    seriesName = seriesName
                      .replace(/_/g, " ")
                      .replace(/\w\S*/g, function (txt) {
                        return (
                          txt.charAt(0).toUpperCase() +
                          txt.substr(1).toLowerCase()
                        );
                      });

                    return `
                        <div style='display:inline-grid; width:200px' > 
                            <div style='grid-area: 1 / 1 / 2 / 2; place-self: start; width:50px;margin:0' >${singleSeries.toLocaleString()}</div>
                            <div style='grid-area: 1 / 2 / 2 / 3; place-self: center;text-align:center;width:100px'>${seriesName}</div>
                            <div style='grid-area: 1 / 3 / 2 / 4; place-self: end; width:50px;text-align:right;'>${perCentage}% </div>
                        </div>
                        `;
                  },
                },
              },
            },
            {
              breakpoint: 1235,
              options: {
                legend: {
                  position: "bottom",
                  height: 220,
                  itemMargin: {
                    horizontal: 2,
                    vertical: 0,
                  },
                },
              },
            },
          ],
        };
      },
    },
  };
</script>

<style scoped>
  #chart {
    height: 230px;
  }

  @media screen and (max-width: 1235px) {
    #chart {
      height: 450px;
      margin-top: 1em;
      margin-bottom: 0px !important;
      margin-left: -40px;
    }

    .chart_section {
      padding-right: 20%;
      padding-left: 20%;
    }
  }

  @media screen and (max-width: 768px) {
    #chart {
      height: 450px;
      margin-top: 1em;
      margin-bottom: 0px !important;
      margin-left: -40px;
    }

    .chart_section {
      padding-right: 20%;
      padding-left: 20%;
    }
  }

  @media screen and (max-width: 500px) {
    #chart {
      height: 450px;
      margin-top: 1em;
      margin-bottom: 0px !important;
      margin-left: -20px;
    }

    .chart_section {
      padding-right: 0%;
      padding-left: 0%;
    }
  }
</style>
