<template>
  <div class="main_container">
    <div class="row pt-5" v-if="fetchingData">
      <div class="col-md-12" v-loading="fetchingData"></div>
    </div>
    <div class="row" v-else>
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-12">
            <div class="reporting mnotify-card">
              <div
                class="mnotify-card-header d-flex justify-content-between align-items-center">
                <h3 class="mnotify-card-header-text">
                  {{ logintype == "sms" ? "SMS" : "Voice" }}
                  {{ $t("dashboard.campaign_history.title") }}
                </h3>
              </div>
              <div class="" v-if="loading">
                <div class="row pt-5">
                  <div class="col-md-12" v-loading="loading"></div>
                </div>
              </div>
              <div class="" v-else>
                <div class="__reporting__table">
                  <div class="contact_table">
                    <el-table
                      :header-cell-style="{
                        background: 'rgba(212, 216, 226, 0.1)',
                      }"
                      :data="pagedTableData"
                      ref="singleTable"
                      style="width: 100vw; font-size: 12px"
                      :row-style="handleRowStyle"
                      @row-click="openCampaignDetails"
                      height="60vh"
                      class="main-table"
                      v-loading="tableLoading">
                      <el-table-column
                        :label="$t('dashboard.campaign_history.button_history.name')"
                        prop="campaign_name"
                        :width="customWidth">
                        <template slot-scope="scope">
                          <span class="notranslate">{{
                            scope.row.campaign_name
                          }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column
                        :label="$t('dashboard.campaign_history.total_recipients')"
                        :width="customWidth">
                        <template slot-scope="scope">
                          {{
                            scope.row.total_recipients
                              ? scope.row.total_recipients
                              : scope.row.contacts
                          }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        :label="$t('dashboard.overview.sender_id')"
                        :width="customWidth"
                        v-if="logintype == 'sms'">
                        <template slot-scope="scope">
                          <span class="notranslate">
                            {{ scope.row.sender_id }}
                          </span>
                        </template>
                      </el-table-column>
                      <el-table-column
                        :label="$t('dashboard.campaign_history.label')"
                        :width="customWidth">
                        <template slot-scope="scope">
                          {{ moment(scope.row.created_at).format("llll") }}
                        </template>
                      </el-table-column>

                      <el-table-column
                        :label="$t('dashboard.campaign_history.total_credit')"
                        :width="customWidth">
                        <template slot-scope="scope">
                          {{
                            scope.row.total_sms_used ||
                            scope.row.total_voice_used
                          }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        :label="$t('dashboard.campaign_history.wallet_balance')"
                        :width="customWidth">
                        <template slot-scope="scope">
                          {{
                            scope.row.wallet_charge
                              ? scope.row.wallet_charge
                              : 0
                          }}
                        </template>
                      </el-table-column>
                    </el-table>
                    <div class="" style="text-align: center; margin-top: 2em">
                      <el-pagination
                        background
                        layout="prev, pager, next"
                        @current-change="handleCurrentChange"
                        :page-size="pageSize"
                        :total="total">
                      </el-pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import RecentSMSChart from "./reporting_sms";
  import BillingHistoryChart from "../main/BillingHistoryChart.vue";
  import store from "@/state/store.js";
  import moment from "moment";
  import loginType from "@/helpers/loginType";

  export default {
    components: {
      RecentSMSChart,
      BillingHistoryChart,
    },
    data() {
      return {
        Campaign: "",
        loading: false,
        page: 1,
        pageSize: 50,
        total: 0,
        campaignHistory: [],
        tableLoading: false,
      };
    },
    methods: {
      handleRowStyle({ row, rowIndex }) {
        return this.campaignHistory && this.campaignHistory[rowIndex].active
          ? { background: "rgba(207, 214, 233, 0.19)" }
          : "";
      },
      handleCurrentChange(val) {
        this.fetchingCampaign(val);
        this.page = val;
      },
      openCampaignDetails(data) {
        let total = data.credit_used ? data.credit_used : data.total_sms_used;
        store.dispatch("reporting/fetchReportingTotalCredit", total);
        if (loginType() === "sms") {
          this.$router
            .push(`/sms/campaign/${data.campaign_id}/details`)
            .catch((err) => {
              if (err.name !== "NavigationDuplicated") throw err;
            });
        } else {
          this.$router
            .push(`/voice/campaign/${data.campaign_id}/details`)
            .catch((err) => {
              if (err.name !== "NavigationDuplicated") throw err;
            });
        }
      },
      fetchingCampaign(whichpage = 1) {
        this.tableLoading = true;
        store
          .dispatch("reporting/fetchSMSHistory", whichpage)
          .then((res) => {
            this.campaignHistory = res.data;
          })
          .finally(() => (this.tableLoading = false));
      },
    },
    mounted() {
      // this.fetchingCampaign()
      this.tableLoading = true;
      store
        .dispatch("reporting/fetchSMSHistory", 1)
        .then((res) => {
          this.campaignHistory = res.data;
          this.total = res.total;
        })
        .finally(() => (this.tableLoading = false));
    },
    computed: {
      pagedTableData() {
        return this.campaignHistory;
      },
      logintype() {
        return loginType();
      },
      // campaignHistory(){
      // 	return store.getters['reporting/campaignHistory']
      // },
      fetchingData() {
        return store.getters["reporting/fetchingData"];
      },
      campaignLabels() {
        return store.getters["reporting/campaignLabels"];
      },
      campaignSeries() {
        return store.getters["reporting/campaignSeries"];
      },
      categories() {
        return store.getters["reporting/billingSumarryCategory"];
      },
      totalRecipients() {
        return store.getters["reporting/billingSumarryRecipients"];
      },
      customWidth() {
        if (screen.width < 768) {
          return "150px";
        } else {
          return "min-content";
        }
      },
    },
  };
</script>

<style scoped>
  .main_container {
    margin-bottom: 3em;
  }

  @import url("../../../styles/__overview.scss");

  #__nodata {
    margin-top: 70%;
  }

  .reporting {
    height: 782px;
  }

  .__reporting__table {
    height: 732px;
  }

  @media screen and (max-width: 768px) {
    #__nodata {
      margin-top: 0%;
    }

    .main-container * {
      background-color: red;
      padding: 0 !important;
      margin: 0 auto !important;
      width: 100%;
    }
  }

  ._campaign_notes {
    font-size: 1.2em;
    color: #000;
    padding: 0.5em 0;
  }

  .main-table {
    cursor: pointer;
  }
</style>
